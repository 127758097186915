class Enum {
    constructor(map) {
        this.map = map;
    }

    getOptions () {
        let options = [];
        for (let key in this.map) {
            options.push({ key: key, value: this.map[key] });
        }
        return options;
    }

    getValue (key) {
        return this.map[key];
    }
}

const CouseType = new Enum({
    1: "一对一",
    2: "大班课",
    3: "小班课",
    4: "公开课",
    5: "点播课",
    7: "面授课",
    8: "音频课",
    9: "系统课",
    10: "图文课",
});

const SearchCouseType = new Enum({
    0: "全部",
    2: "大班课",
    3: "小班课",
    4: "公开课",
    5: "点播课",
    8: "音频课",
    10: "图文课",
    7: "面授课",
    9: "系统课",
    "-1": "会员课",
});

const PayStatus = new Enum({
    1: "待支付",
    2: "已支付",
    3: "退款申请中",
    4: "退款中",
    5: "已退款",
    6: "退款失败"
});

const OrderStatus = new Enum({
    1: "待支付",
    2: "交易完成",
    3: "交易关闭"
});

const ShopType = new Enum({
    1: "一对一",
    2: "大班课",
    3: "小班课",
    4: "公开课",
    5: "点播课",
    7: "面授课",
    8: "音频课",
    9: "系统课",
    10: "图文课",
    11: "充值余额",
    12: "图书",
    13: "文库",
    14: "会员",
    15: '章节练习',
    16: '模拟考试',
    17: '付费回答'
});

const PayType = new Enum({
    1: "微信",
    2: "支付宝",
    3: "虚拟币支付",
    4: "苹果支付",
    5: "兑换",
    6: "赠送",
    7: "优惠券支付",
    8: "线下支付",
    9:"余额支付",
    19:"积分兑换"
});

const LiveStatus = new Enum({
    1: '未开始',
    2: '直播中',
    3: '已结束'
});

const ExamTopicType = new Enum({
    1: '单选题',
    2: '多选题',
    3: '判断题',
    // 4: '连线题',
    5: '简答题'
});

// 考试类型
const ExamType = new Enum({
    1: '模拟考试',
    2: '正式考试'
});

// 作业状态
const HomeworkStatus = new Enum({
    0: '草稿',
    1: '待批阅',
    2: '已关闭',
    3: '已完成',
    4: '已过期',
    5: '未完成'
});

// 试题类型
const QuestionType = new Enum({
    1: '单选题',
    2: '多选题',
    3: '判断题',
    // 4: '连线题',
    5: '简答题'
});

// 发货状态
// 0：已完成 1待发货 2部分发货 3已发货 4待评价 5:取消发货
const ShipStatus = new Enum({
    1: '待发货',
    2: '无需发货',
    3: '已发货',
    4: '已收货'
});
const GiftShipStatus = new Enum({
    0: '已完成',
    1: '待发货',
    2: '部分发货',
    3: '已发货',
    4: '待评价',
    5: '取消发货'
})
// 提现审核状态
const WithdrawStatus = new Enum({
    1: '通过',
    2: '未通过',
    3: '待审核'
})
export {
    CouseType,
    SearchCouseType,
    PayStatus,
    OrderStatus,
    ShopType,
    PayType,
    LiveStatus,
    ExamTopicType,
    ExamType,
    HomeworkStatus,
    QuestionType,
    ShipStatus,
    GiftShipStatus,
    WithdrawStatus
};
