import Vue from 'vue';
import * as enums from './enums';

Vue.filter('price', function(value) {
    return (value / 100).toFixed(2);
});

Vue.filter('sex', function(value) {
    return value == 0 ? '男' : '女';
});

Vue.filter('shopType', function(value) {
    return enums.ShopType.getValue(value);
});

Vue.filter('payStatus', function(value) {
    return enums.PayStatus.getValue(value);
});

Vue.filter('orderStatus', function(value) {
    return enums.OrderStatus.getValue(value);
});

Vue.filter('shipStatus', function(value) {
    return enums.ShipStatus.getValue(value);
});

Vue.filter('payType', function(value) {
    return enums.PayType.getValue(value);
});

Vue.filter('tsFormat', function(value, format) {
    if (value === undefined || value === '') return '';
    value = parseInt(value);
    return new Date(value * 1000).format(format);
});
Vue.filter('toFixPrice', function(val, toFix = 2) {
    let price = 0
    if (val) {
        if (toFix == 2) {
            price = (val / 100).toFixed(2)
        }
        if (toFix == 1) {
            price = (val / 100).toFixed(1)
        }
        if (toFix == 0) {
            price = (val / 100)
        }
    }
    return price
});