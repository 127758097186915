import Vue from 'vue';

Date.prototype.format = function(fmt) {
    var o = {
        "M+": this.getMonth() + 1, //月份   
        "d+": this.getDate(), //日   
        "h+": this.getHours(), //小时   
        "m+": this.getMinutes(), //分   
        "s+": this.getSeconds(), //秒   
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度   
        "S": this.getMilliseconds() //毫秒   
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

Vue.prototype.$messagePromise = function(msg, config) {
    return new Promise(resolve => {
        config = Object.assign({
                message: msg,
                type: 'info',
                duration: 1000,
                showClose: false,
                onClose: function() {
                    resolve();
                },
            },
            config
        );
        this.$message(config);
    });
};

Vue.prototype.$success = function(msg, config = {}) {
    config.type = 'success';
    return this.$messagePromise(msg, config);
};

Vue.prototype.$warning = function(msg, config = {}) {
    config.type = 'warning';
    return this.$messagePromise(msg, config);
};

Vue.prototype.$error = function(msg, config = {}) {
    config.type = 'error';
    return this.$messagePromise(msg, config);
};

Vue.prototype.$info = function(msg, config = {}) {
    config.type = 'info';
    return this.$messagePromise(msg, config);
};

Vue.prototype.$promiseConfirm = function(msg, config = {}) {
    return new Promise(resolve => {
        config = Object.assign({ type: 'info', dangerouslyUseHTMLString: true },
            config
        );
        this.$confirm(msg, config.title || '提示', {
            ...config,
            callback: function(action) {
                if (action == 'confirm') {
                    resolve();
                } else if (config.callback) {
                    config.callback(action);
                }
            },
        });
    });
};

Vue.prototype.$storage = function() {
    let that = this;
    return {
        set: (key, data) => {
            let rawData = localStorage.getItem(key);
            if (rawData) {
                rawData = JSON.parse(rawData);
            } else {
                rawData = {};
            }
            rawData[that.$store.state.user_info.id] = data;
            localStorage[key] = JSON.stringify(rawData);
        },
        get: key => {
            let rawData = localStorage.getItem(key);
            if (rawData) {
                let data = JSON.parse(rawData)[
                    that.$store.state.user_info.id
                ];
                return data;
            }
        },
    };
};