import http from '../modules/stu/http'

//时间转换
function tsToTime (timestamp, hasSecond = true) {
    var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + '.';
    var M =
        (date.getMonth() + 1 < 10 ?
            '0' + (date.getMonth() + 1) :
            date.getMonth() + 1) + '.';
    var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    var h =
        (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    var s =
        ':' +
        (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    if (hasSecond) {
        return Y + M + D + h + m + s;
    } else {
        return Y + M + D + h + m;
    }
}

function getWeek (timeStamp) {
    var date = new Date(timeStamp * 1000);
    var day = date.getDay();
    if (day == 0) {
        return '周日';
    } else if (day == 1) {
        return '周一';
    } else if (day == 2) {
        return '周二';
    } else if (day == 3) {
        return '周三';
    } else if (day == 4) {
        return '周四';
    } else if (day == 5) {
        return '周五';
    } else if (day == 6) {
        return '周六';
    }
    return '';
}

// 2019-6-24
function timestampToDay (timestamp) {
    var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + '-';
    var M =
        (date.getMonth() + 1 < 10 ?
            '0' + (date.getMonth() + 1) :
            date.getMonth() + 1) + '-';
    var D =
        date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
    return Y + M + D;
}

/**
 * 时间戳 => 10-10
 * */
function timestampToMD (timestamp) {
    var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var M =
        (date.getMonth() + 1 < 10 ?
            '0' + (date.getMonth() + 1) :
            date.getMonth() + 1) + '.';
    var D =
        (date.getDate() + 1 < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    return M + D;
}

/**
 * 时间戳 => 10:10
 * */

function timestampToHM (timestamp) {
    var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var h =
        (date.getHours() + 1 < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    var m =
        date.getMinutes() + 1 < 10 ? '0' + date.getMinutes() : date.getMinutes();
    // var s = (date.getSeconds() + 1 < 10 ? '0' + date.getSeconds() : date.getSeconds())
    return h + m;
    // return Y + M + D;
}

/**
 * 时间戳 =>判断时间戳区间（ 0:今年不跨日 / 1:今年跨日 / 2:往年不跨日 / 3:往年跨日 / 4：跨年 ）
 * */
function time_judge (time_start, time_end) {
    var time_s = new Date(time_start * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var time_e = new Date(time_end * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var today = new Date(); //当前时间
    var Y_s = time_s.getFullYear() + '.';
    var Y_e = time_e.getFullYear() + '.';
    var Y_n = today.getFullYear() + '.';
    var M_s =
        (time_s.getMonth() + 1 < 10 ?
            '0' + (time_s.getMonth() + 1) :
            time_s.getMonth() + 1) + '.';
    var M_e =
        (time_e.getMonth() + 1 < 10 ?
            '0' + (time_e.getMonth() + 1) :
            time_e.getMonth() + 1) + '.';
    var D_s =
        (time_s.getDate() + 1 < 10 ? '0' + time_s.getDate() : time_s.getDate()) +
        ' ';
    var D_e =
        (time_e.getDate() + 1 < 10 ? '0' + time_e.getDate() : time_e.getDate()) +
        ' ';
    //判断
    if (Y_s == Y_e) {
        //同年
        if (Y_s == Y_n) {
            //今年
            if (M_s == M_e && D_s == D_e) {
                //今年不跨日
                return 0;
            } else {
                //今年跨日
                return 1;
            }
        } else {
            //往年
            if (M_s == M_e && D_s == D_e) {
                //往年不跨日
                return 2;
            } else {
                //往年跨日
                return 3;
            }
        }
    } else {
        //跨年
        return 4;
    }
}

// 获取时间跨度
function getDuration (start_date, end_date) {
    let dateStr;
    // 0:今年不跨日 / 1:今年跨日 / 2:往年不跨日 / 3:往年跨日 / 4：跨年
    const type = time_judge(start_date, end_date);
    switch (type) {
        case 0:
            {
                dateStr =
                    timestampToMD(start_date) +
                    timestampToHM(start_date) +
                    ' - ' +
                    timestampToHM(end_date);
            }
            break;
        case 1:
            {
                dateStr =
                    timestampToMD(start_date) +
                    timestampToHM(start_date) +
                    ' - ' +
                    timestampToMD(end_date) +
                    timestampToHM(end_date);
            }
            break;
        case 2:
            {
                dateStr =
                    timestampToDay(start_date) +
                    timestampToHM(start_date) +
                    ' - ' +
                    timestampToHM(end_date);
            }
            break;
        case 3:
            {
                dateStr =
                    timestampToDay(start_date) +
                    timestampToHM(start_date) +
                    ' - ' +
                    timestampToMD(end_date) +
                    timestampToHM(end_date);
            }
            break;
        case 4:
            {
                dateStr =
                    timestampToDay(start_date) +
                    timestampToHM(start_date) +
                    ' - ' +
                    timestampToDay(end_date) +
                    timestampToHM(end_date);
            }
            break;
        default:
            dateStr = '不支持的时间格式';
            break;
    }
    return dateStr;
}

function debounce (func, wait) {
    let timeout;
    return function () {
        let context = this;
        let args = arguments;

        if (timeout) clearTimeout(timeout);

        timeout = setTimeout(() => {
            func.apply(context, args)
        }, wait);
    }
}

// 获取字母序号 number >= 1
function getLetterNumber (number) {
    return String.fromCharCode(64 + number);
}

function toFixed (num, s) {
    var times = Math.pow(10, s)
    var des = num * times + 0.5
    des = parseInt(des, 10) / times
    return des + ''
}

// 数组元素交换
function swapArrayItem (arr, fromIndex, toIndex) {
    arr[toIndex] = arr.splice(fromIndex, 1, arr[toIndex])[0];
    return arr;
}

// 获取当前时间戳，单位秒
function getCurTimestamp () {
    return parseInt(new Date().getTime() / 1000);
}
function clickLike (params) {
    return new Promise((resolve, reject) => {
        http.post(`/api/app/ask/clickLike`, params).then(res => {
            resolve(res);
        });
    })
}
function formatSeconds (value) {
    var secondTime = parseInt(value);// 秒
    var minuteTime = 0;// 分
    var hourTime = 0;// 小时
    if (secondTime > 60) {//如果秒数大于60，将秒数转换成整数
        //获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60);
        //获取秒数，秒数取佘，得到整数秒数
        secondTime = parseInt(secondTime % 60);
        //如果分钟大于60，将分钟转换成小时
        // if (minuteTime > 60) {
        //   //获取小时，获取分钟除以60，得到整数小时
        //   hourTime = parseInt(minuteTime / 60);
        //   //获取小时后取佘的分，获取分钟除以60取佘的分
        //   minuteTime = parseInt(minuteTime % 60);
        // }
    } else {
        result = "" + 0 + "′" + parseInt(secondTime) + '″';
    }
    var result = "" + parseInt(secondTime) + "″";
    if (minuteTime > 0) {
        result = "" + parseInt(minuteTime) + "′" + result;
    }
    if (hourTime > 0) {
        result = "" + parseInt(hourTime) + "小时" + result;
    }
    return result;
}
 function dateDifference (faultDate, completeTime) {
    // let d1 = new Date(faultDate);
    // let d2 = new Date(completeTime);
    // var stime = new Date(faultDate).getTime();
    // var etime = new Date(completeTime).getTime();
    var usedTime = completeTime - faultDate;  //两个时间戳相差的毫秒数
    var days = Math.floor(usedTime / (24 * 3600 * 1000));
    //计算出小时数
    var leave1 = usedTime % (24 * 3600 * 1000);    //计算天数后剩余的毫秒数
    var hours = Math.floor(leave1 / (3600 * 1000));
    //计算相差分钟数
    var leave2 = leave1 % (3600 * 1000);        //计算小时数后剩余的毫秒数
    var minutes = Math.floor(leave2 / (60 * 1000));
    // var time = days + "天"+hours+"时"+minutes+"分";
    var time = days;
    return minutes;
}
export default {
    tsToTime,
    getWeek,
    timestampToDay,
    timestampToMD,
    timestampToHM,
    time_judge,
    getDuration,
    debounce,
    getLetterNumber,
    toFixed,
    swapArrayItem,
    getCurTimestamp,
    clickLike,
    formatSeconds,
    dateDifference
};