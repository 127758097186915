import { Guid } from '../../utils/guid';

const buildConfig = require('../../build/' + process.env.BUILD_ENV + '.js');

const Http = function() {
    let DeviceID = localStorage.DeviceID;
    if (!DeviceID) {
        DeviceID = Guid.NewGuid().ToString('D');
        localStorage.DeviceID = DeviceID;
    }
    this.DeviceID = DeviceID;
};

/**
 * fetch请求
 * @param {String} url 接口地址
 * @param {String} method http方法
 * @param {Object} params 请求参数
 * @param {Boolean|Object} httpConfig 配置1、布尔类型：接口是否有权限控制，默认false 2、对象类型：isAuthRequest:接口是否有权限控制，默认false；showLoading：是否显示loading效果，默认true
 * @returns {Object}
 */
Http.prototype.fetch = function(url, method, params = {}, httpConfig = {}) {
    let isAuthRequest = httpConfig.isAuthRequest;
    let showLoading = httpConfig.showLoading;
    url = buildConfig.BASE_URL + url;
    if (this.beforeRequest) {
        if (!this.beforeRequest(isAuthRequest, showLoading)) {
            return new Promise(() => {});
        }
    }
    let config = { method: method, mode: 'cors' };
    config.headers = {
        DeviceType: 'PC',
        DeviceID: this.DeviceID,
    };
    if (params instanceof FormData) {
        config.body = params;
    } else {
        if (method == 'get' || method == 'head') {
            if (url.indexOf('?') > 0) {
                url += '&';
            } else {
                url += '?';
            }
            for (let key in params) {
                let value = params[key];
                if (typeof(value) == 'function' || value === undefined || value === '') {
                    continue;
                }
                url += key + '=' + params[key] + '&';
            }
        } else {
            config.body = JSON.stringify(params);
        }
        config.headers['Content-Type'] = 'application/json';
    }
    if (localStorage.studentToken) {
        config.headers.Authorization = 'Bearer ' + localStorage.studentToken;
    }
    return new Promise((resolve, reject) => {
        fetch(url, config).then(async res => {
            if (this.afterRequest) {
                this.afterRequest();
            }
            if (res.status == 200) {
                let data = await res.json();
                if (data.code == 200) {
                    resolve(data.data || data);
                } else if (data.code == 203) {
                    resolve(data);
                } else {
                    if (this.onApiError) {
                        this.onApiError(data);
                    }
                    //reject(data.msg);
                }
            } else {
                reject(res.statusText);
            }
        });
    });
};

function buildHttpConfig(isAuthRequest) {
    let config;
    if (typeof isAuthRequest == 'boolean') {
        config = { isAuthRequest };
    } else {
        config = isAuthRequest;
    }
    if (config.showLoading === undefined) {
        config.showLoading = true;
    }
    return config;
}

Http.prototype.get = function(url, params, isAuthRequest = false) {
    return this.fetch(url, 'get', params, buildHttpConfig(isAuthRequest));
};

Http.prototype.post = function(url, params, isAuthRequest = false) {
    return this.fetch(url, 'post', params, buildHttpConfig(isAuthRequest));
};

Http.prototype.put = function(url, params, isAuthRequest = false) {
    return this.fetch(url, 'put', params, buildHttpConfig(isAuthRequest));
};

Http.prototype.delete = function(url, params, isAuthRequest = false) {
    return this.fetch(url, 'delete', params, buildHttpConfig(isAuthRequest));
};

export default new Http();