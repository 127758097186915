<template>
  <img :src="$store.state.webConfig.price_ico" v-if="currency==1" />
  <span v-else>¥</span>
</template>
<script>
export default {
  name: "Currency",
  data() {
    return {
      currency: 1
    };
  }
};
</script>