<template>
  <div>
    <slot :list="list"></slot>
    <el-pagination
      v-if="total>0&&(total>limit||alwaysShowPager)"
      class="pager"
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="changePage"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  name: "List",
  props: {
    url: {
      type: String
    },
    method: {
      type: String,
      default: "get"
    },
    search: {
      type: Object
    },
    alwaysShowPager: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      list: [],
      page: 1,
      limit: 10,
      total: 0
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    async getData() {
      let data = await this.$http[this.method](this.url, {
        page: this.page,
        limit: this.limit,
        ...(this.search || {})
      });
      this.list = data.list;
      this.total = data.total;
    },
    async searchRefresh() {
      this.page = 1;
      await this.getData();
    },
    async changePage(page) {
      this.page = page;
      await this.getData();
    }
  }
};
</script>
<style lang="scss" scoped>
.pager {
  margin: 0;
}
</style>