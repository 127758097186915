<template>
  <div class="footerBar" :class="{'is-login':login,'shadow':$route.name=='Download'}">
    <ul class="link">
      <a href="/teacher/" target="_blank">
        <span>讲师登录</span>
      </a>
      <router-link v-if="!teacher" tag="li" title="帮助中心" :to="{ name: 'HelpCenter' }">
        <span>帮助中心</span>
      </router-link>
      <router-link v-if="!teacher" tag="li" title="联系我们" :to="{ name: 'ContactUs' }">
        <span>联系我们</span>
      </router-link>
      <router-link v-if="!teacher" tag="li" title="关于我们" :to="{ name: 'About' }">
        <span>关于我们</span>
      </router-link>
      <li title="服务热线" class="phone">
        <span>服务热线：{{$store.state.webConfig.contact}}</span>
      </li>
    </ul>
    <p class="copyright">
      {{$store.state.webConfig.copyright}}
      <a
        :href="$store.state.webConfig.record_no_link"
        target="_blank"
      >{{$store.state.webConfig.record_no}}</a>
    </p>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    login: {
      default: false
    },
    teacher: {
      default: false
    }
  },
  data() {
    return {};
  },
  mounted: function() {}
};
</script>
<style lang="scss" scoped>
.footerBar {
  padding-top: 42px;
  height: 106px;
  background: #373738;
  color: #fff;
  box-sizing: border-box;
  &.shadow {
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  }
}

.footerBar .link {
  text-align: center;
}

.footerBar .link li,
.footerBar .link a {
  padding: 0 30px;
  display: inline-block;
  // border-right: 1px solid #fff;
  font-size: 18px;
  color: #fff;
  // color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  position: relative;
  &::after {
    content: "";
    width: 1px;
    height: 18px;
    background-color: #e8e8e8;
    position: absolute;
    right: 0;
    top: 4px;
  }
  &:last-child::after {
    display: none;
  }
}

.footerBar .link li.phone {
  border: none;
}

.footerBar .link li span,
.footerBar .link li a {
  display: block;
}

.footerBar .copyright {
  margin-top: 10px;
  height: 19px;
  text-align: center;
  font-size: 15px;
  color: #fff;
  line-height: 19px;
  a{
    color: #fff;
  }
}
</style>
