<template>
  <div class="center" :style="{padding: this.tbPadding + 'px' + ' 0'}">
    <div>
      <img src="@/assets/images/empty@3x.png" :style="{width: this.imgWidth + 'px'}" />
    </div>
    <div>{{title}}</div>
  </div>
</template>
<script>
export default {
  name: "Empty",
  props: {
    title: {
      type: String,
      default: "暂无数据"
    },
    imgWidth: {
      type: Number,
      default: 94
    },
    tbPadding: {
      type: Number,
      default: 104
    }
  }
};
</script>
<style lang="scss" scoped>
.center {
  background-color: white;
  color: #8c8c8c;
  img {
    margin-bottom: 20px;
  }
}
</style>