<template>
<div id="goTop" v-show="goTopShow">
  <div class="goTop" @click="goTop">
    <img class="goTopIcon" src="~@/assets/images/icon_gotop_top.png" />
    <p>TOP</p>
  </div>
</div>
</template>
<script>
export default {
  name: "goTop",
  data() {
    return {
      scrollTop: '',
      goTopShow: false,
    }
  },
  methods: {
    handleScroll() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (this.scrollTop > 500) {
        this.goTopShow = true
      } else {
        this.goTopShow = false
      }
    },
    goTop() {
      let timer = null,
        that = this;
      cancelAnimationFrame(timer)
      timer = requestAnimationFrame(function fn() {
        if (that.scrollTop > 0) {
          that.scrollTop -= 50;
          document.body.scrollTop = document.documentElement.scrollTop = that.scrollTop;
          timer = requestAnimationFrame(fn)
        } else {
          cancelAnimationFrame(timer);
          that.goTopShow = false;
        }
      })
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style scoped lang="scss">
#goTop {
  position: fixed;
  right: 0;
  bottom: 200px;
  z-index: 999999999;
}

.goTop {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  width:104px;
  height:86px;
  background:rgba(255,255,255,1);
  box-shadow:0px 0px 14px 0px rgba(155,155,155,0.42);
  border-radius: 50px 0px 0px 50px;
  cursor: pointer;
  p{
    color: #333333;
  }
}

.goTopIcon {
  display: block;
  width: 28px;
  height: 43px;
}
</style>
